import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import Mdlguser from './Mdlgscreen/mdlguser';
import Smuser from './Smscreen/smuser';
import { useParams } from 'react-router-dom';
import { fetchUserData } from '../../Action/smartsocial/userprofile';

const UserProfile = () => {
  const dispatch = useDispatch();
  const { userid } = useParams();
  const userProfile = useSelector((state) => state.userProfile.userProfile);
  const loadingProfile = useSelector((state) => state.userProfile.loading);
  const error = useSelector((state) => state.userProfile.error);

  const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  const isSM = useMediaQuery({ query: '(min-width: 640px)' });

  useEffect(() => {
    dispatch(fetchUserData(userid));
  }, [dispatch, userid]);

  return (
    <div className="flex-1 justify-center flex ">
      {is2XL ? <Mdlguser user={userProfile} loading={loadingProfile} error={error} /> :
        isXL ? <Mdlguser user={userProfile} loading={loadingProfile} error={error} /> :
          isLG ? <Mdlguser user={userProfile} loading={loadingProfile} error={error} /> :
            isMD ? <Mdlguser user={userProfile} loading={loadingProfile} error={error} /> :
              isSM ? <Smuser user={userProfile} loading={loadingProfile} error={error} /> :
                <Smuser user={userProfile} loading={loadingProfile} error={error} />}
    </div>
  );
};

export default UserProfile;

// import React, { useEffect, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useMediaQuery } from 'react-responsive';
// import Mdlguser from './Mdlgscreen/mdlguser';
// import Smuser from './Smscreen/smuser';
// import { useParams } from 'react-router-dom';
// import axios from 'axios';

// const UserProfile = () => {
//   const dispatch = useDispatch();
//   const { userid } = useParams();
//   const [userProfile, setUserProfile] = useState(null);
//   const [loadingProfile, setLoadingProfile] = useState(false);
//   const [error, setError] = useState(null);

//   const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
//   const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
//   const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
//   const isMD = useMediaQuery({ query: '(min-width: 768px)' });
//   const isSM = useMediaQuery({ query: '(min-width: 640px)' });

//   useEffect(() => {
//     const fetchUserData = async () => {
//       setLoadingProfile(true);
//       try {
//         const token = localStorage.getItem('token'); 
//         const response = await axios.get(`http://api.smartsuperapp.com/profile/v2/view?userId=${userid}&page_number=0`, {
//           headers: {
//             'Authorization': `Bearer ${token}`
//           }
//         });
//         setUserProfile(response.data.data); 
//         setLoadingProfile(false);
//       } catch (error) {
//         setError(error);
//         setLoadingProfile(false);
//       }
//     };

//     fetchUserData();
//   }, [userid]);

//   return (
//     <div className="flex-1 mt-4 overflow-y-auto justify-center flex scroll-smooth scrollbar-hide">
//       {is2XL || isXL || isLG || isMD ?
//         <Mdlguser user={userProfile} loading={loadingProfile} error={error} /> :
//         <Smuser user={userProfile} loading={loadingProfile} error={error} />}
//     </div>
//   );
// };

// export default UserProfile;
  