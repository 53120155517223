import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';

import './style.css';

const List = ({data,images}) => {
  const [showFullEmail, setShowFullEmail] = useState(false);

  const email = data?.agent_contact_info?.agent?.email || "";

   

    return (
        <div className="2xl:mt-10  xl:mt-10 lg:mt-8 2xl:w-[380px] 2xl:h-[490px] xl:w-[380px] xl:h-[490px] lg:w-[320px] lg:h-[450px]  border  shadow-2xl   border-blue-800 rounded-lg  ">
      <h1 className="font-semibold 2xl:text-3xl xl:text-3xl lg:text-2xl mx-2 mt-8 text-blue-800 flex justify-center"> 
        Listing Office and Agent
        </h1>
        
        <div className="flex items-center justify-center 2xl:my-12 xl:my-12 lg:my-10 mx-4">
          <img className="2xl:w-32 2xl:h-32  xl:w-32 xl:h-32 lg:w-28 lg:h-28 rounded-xl   border-2 border-blue-800 " src={data?.agent_contact_info?.agent?.image} alt="Profile" />
            </div>

            <div className="my-0 mx-4  mt-6 flex items-center">
        <span className="font-semibold text-xl   text-blue-900">Name:</span> 

        <span className=" font-normal text-lg text-black ml-2">{data?.agent_contact_info?.agent?.name}</span> 

      </div>
      <div className="mt-2 mx-4">
        <span className="font-semibold text-xl my-2 text-blue-900">Email:</span> 
         <span className="font-normal text-lg text-black ml-2 break-words whitespace-normal">
      {showFullEmail ? email : email.slice(0, 20) + (email.length > 20 ? "..." : "")}
    </span>
        {email.length > 20 && (
          <button
            onClick={() => setShowFullEmail(!showFullEmail)}
            className="ml-1 text-gray-400 underline text-xs"
          >
            {showFullEmail ? "Show Less" : "Show More"}
          </button>
        )}

      </div>
      <div className='mt-2 mx-4'>
        <span className="font-semibold text-xl my-2  text-blue-900">Phone:</span> 
        <span className=" font-normal text-lg text-black ml-2">{data?.agent_contact_info?.agent?.phones[0]?.number}</span> 

      </div>
            
     </div>
  );
}

export default List;

