import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { fetchData } from './home';
import Header from "./Header";
import SubHeader from "./SubHeader";
import MiddleContent from "./Middlecontent";
import Loader from "../../Realestate/Middlecontent/loader"; 
import List from './list';
import Headerskeleton from './Headerskeleton';
import Listskeleton from './listskeleton';
import SubHeaderskeleton from './subheaderskeleton';
import Middleskeleton from './middleskeleton';
import { connect } from "react-redux";
import {  useLocation, Navigate } from "react-router-dom";

const HomeDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [images, setImages] = useState([]);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { propertyId } = useParams();
  const userData = JSON.parse(localStorage.getItem('userData'));

  useEffect(() => {
    fetchData(propertyId)
        .then(response => {
            const responseData = response.data;
            setData(responseData);

            if (response.data.photos && response.data.photos.length > 0) {
                const imageUrls = response.data.photos.map(photo => photo.href);
                setImages(imageUrls);
            }
           
            setLoading(false);
        })
        .catch(error => {
            setLoading(false); 
        }); window.scrollTo(0, 0);
      }, [propertyId]);
    

  const goBack = () => {
    navigate('/realestate');
  };

useEffect(() => {
  setTimeout(() => {
    setLoading(false);
  },20000);
}, []);

  // if (loading) {
  //   return <Loader />;
  // }

  if (!userData) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return (
    <div className="min-h-screen flex flex-col items-center">
  <header className={`bg-gray-100   w-full fixed top-20  z-10 `}>
    <button
      type="button"
      onClick={goBack}
      className="text-white mx-5 bg-blue-700 my-2 hover:bg-blue-800 w-20 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-2 py-2.5 text-center inline-flex items-center"
    >
      <svg
        className="ltr:rotate-90 w-3.5 h-3.5 transform -rotate-180 mr-1"
        aria-hidden="true"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 14 10"
      >
        <path
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M1 5h12m0 0L9 1m4 4L9 9"
        />
      </svg>
      Back
    </button>
  </header>

  <section className="flex-grow  flex mt-32">
    <div className="flex flex-col max-w-5xl w-full mr-0 items-center ">
       <section className='w-full' >
          {loading ? <Headerskeleton /> : <Header data={data} images={images} /> }
       </section>
       <section className='mt-6 mx-4 w-full '>
          {loading ?  <SubHeaderskeleton /> : <SubHeader data={data} />}
       </section>
       <section className='w-full' >
          {loading ? <Middleskeleton /> : <MiddleContent data={data} />}
       </section>
    </div>
    <section className='flex-grow pr-1 flex-col hidden lg:block  '>
    {loading ? <Listskeleton /> : <List data={data} /> }
    </section>
  </section>
</div>

  );
};
const mapStateToProps = state => ({
    real_estate_view: state.view.real_estate_view
  });



export default connect(mapStateToProps)(HomeDetail);
