
import React, { useState, useEffect,useRef } from 'react';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
// import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Header = ({data,images}) => {
   
    const [activeIndex, setActiveIndex] = useState(0);
    const sliderRef = useRef(null);
 

    
    const prevSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const nextSlide = () => {
        setActiveIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

//    const sliderLeft = () => {
//     var slider = document.getElementById('slider');
//     slider.scrollLeft = slider.scrollLeft - 500;
//    }

//    const sliderRight = () => {
//     var slider = document.getElementById('slider');
//     slider.scrollLeft = slider.scrollLeft + 500;
//    }

   useEffect(() => {
    if (sliderRef.current) {
        const containerWidth = sliderRef.current.offsetWidth;
        const activeImageWidth = sliderRef.current.childNodes[activeIndex].offsetWidth;
        const activeImagwOffset = activeIndex * activeImageWidth;
        const scrollPostion = activeImagwOffset - (containerWidth / 2) + (activeImageWidth / 2);
        
        sliderRef.current.scrollLeft = scrollPostion;
    }
   }, [activeIndex]);

    return (
      <div className="mt-6  " >

        <div id="animation-carousel" className="relative     mx-4  " data-carousel="static"  >
            <div className="relative h-96   object-cover  overflow-hidden rounded-lg ">
                {images.map((image, index) => (
                    <div key={index} 
                       className={index === activeIndex ? '' : 'hidden' } 
                       data-carousel-item="active">
                        <img src={image} className="absolute block  object-cover cursor-pointer  -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt={`Carousel ${index + 1}`} />
                    </div>
                ))}
            </div>
           

            <div className="absolute top-1/2 transform -translate-y-1/2 left-0  cursor-pointer group focus:outline-none" onClick={(e) => e.stopPropagation()}>
              <button type="button" className="flex items-center justify-center  mx-4 w-10 h-10 bg-black/30 group-hover:bg-black/50 rounded-full" onClick={prevSlide}>
                <svg className="w-4 h-4 text-white rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                </svg>
               <span className="sr-only">Previous</span>
             </button>
            </div>

           <div className="absolute top-1/2 transform -translate-y-1/2 right-0 cursor-pointer group focus:outline-none " onClick={(e) => e.stopPropagation()}>
            <button type="button" className=" flex items-center justify-center  mx-4 w-10 h-10 bg-black/30 group-hover:bg-black/50 rounded-full" onClick={nextSlide}>
                <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-black/30  group-hover:bg-black/50    ">
                    <svg className="w-4 h-4 text-white  rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                        <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                    </svg>
                    <span className="sr-only">Next</span>
                </span>
            </button>
          </div>

            <div className="absolute top-2/2 my- xl:my-0  transform -translate-y-1/2 bottom-0  cursor-pointer group focus:outline-none " onClick={(e) => e.stopPropagation()}>
            <button type="button" className="flex  mx-4 ">
                     <span className="inline-flex items-center justify-center w-24 h-10 rounded-full bg-black/30 group-hover:bg-black/50">
                        <CameraAltOutlinedIcon className="text-white/80 w-4 h-4 " />
                        &nbsp;&nbsp;
                         <span className="text-white/80 "> {activeIndex + 1}/{images.length}</span>
                     </span>
             </button>
             </div>
        </div>
        <div className="relative flex items-center  xl:ml-20 mx-4   mt-2  ">
            <div ref={sliderRef} id='slider' className="w-full h-full overflow-x-auto scroll  whitespace-nowrap scroll-smooth scrollbar-hide">
              {images.map((image, index) => (
             <img 
            key={index}
            style={{borderRadius: '24px'}}
            className={`xl:w-48 w-24 xl:h-32 h-24  inline-block mx-1 cursor-pointer p-1 hover:scale-105 ease-in-out duration-300  ${index === activeIndex ? 'border-4 border-black' : ''}`} 
            src={image} 
            alt={'Property Image'} 
            onClick={() => setActiveIndex(index)}
            />
            ))}
       </div>

            </div>
        </div>
    );
}
export default Header;
