// import React, { useState, useEffect, useRef } from "react";
// import { GOOGLE_MAP_API_KEY } from "../../../../Config/config";
// import CircularProgress from "@mui/material/CircularProgress";
// import { GoogleMap, useLoadScript, Marker, OverlayView } from '@react-google-maps/api';
// import Pin from "../../../../assets/images/google-maps.png";
// import { useDispatch } from "react-redux";
// import { fetchProperties, updatePageNumber } from "../../../Action/propertyAction";
// import MarkerListingproperty from './MarkerListingproperty';

// const libraries = ['places'];
// const defaultCenter = { lat: 40.7128, lng: -74.0060 };

// const CustomMap = ({ propertie }) => {
//   const [coordinates, setCoordinates] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [mapBounds, setMapBounds] = useState(null);
//   const mapRef = useRef(null);
//   const previousBounds = useRef(null);
//   const dispatch = useDispatch();
//   const [mapCenter, setMapCenter] = useState(defaultCenter);
//   const [selectedListing, setSelectedListing] = useState(null);
//   const markerRefs = useRef([]);
//   const [isUserInteraction, setIsUserInteraction] = useState(true);
//   const [isResizing, setIsResizing] = useState(false);

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: `${GOOGLE_MAP_API_KEY}`,
//     libraries,
//   });

//   useEffect(() => {
//     const savedCenter = localStorage.getItem("mapCenter");
//     if (savedCenter) {
//       setMapCenter(JSON.parse(savedCenter));
//     }
//   }, []);

//   useEffect(() => {
//     const searchType = localStorage.getItem('searchType');
//     if (searchType === 'address' && propertie.length) {
//       const propertiesWithLatLng = propertie.filter(
//         (property) => property.address.lat !== 'unavailable' && property.address.lon !== 'unavailable'
//       );

//       if (propertiesWithLatLng.length) {
//         const sumLat = propertiesWithLatLng.reduce(
//           (acc, property) => acc + parseFloat(property.address.lat),
//           0
//         );
//         const sumLng = propertiesWithLatLng.reduce(
//           (acc, property) => acc + parseFloat(property.address.lon),
//           0
//         );
//         const avgLat = sumLat / propertiesWithLatLng.length;
//         const avgLng = sumLng / propertiesWithLatLng.length;
//         const newCenter = { lat: avgLat, lng: avgLng };
//         updateMapCenter(newCenter);  
//       }
//     }
//   }, [propertie]);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const newCenter = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           };
//           setCoordinates(newCenter);
//           localStorage.setItem("coordinates", JSON.stringify(newCenter));
//           setLoading(false);
//         },
//         (error) => {
//           console.error("Error fetching the user's location: ", error);
//           setLoading(false);
//         },
//         {
//           enableHighAccuracy: true,
//           timeout: 10000,
//           maximumAge: 0,
//         }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//       setLoading(false);
//     }
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsResizing(true);
//       setTimeout(() => setIsResizing(false), 1000); 
//     };
    
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);
  
//   const handleMapIdle = () => {
//     if (isResizing) return;  
  
//     if (mapRef.current && isUserInteraction) {
//       const bounds = mapRef.current.getBounds();
//       if (bounds) {
//         const ne = bounds.getNorthEast();
//         const sw = bounds.getSouthWest();
//         const nw = { lat: ne.lat(), lng: sw.lng() };
//         const se = { lat: sw.lat(), lng: ne.lng() };
  
//         const newBounds = [
//           [nw.lng, nw.lat],
//           [ne.lng(), ne.lat()],
//           [se.lng, se.lat],
//           [sw.lng(), sw.lat()],
//           [nw.lng, nw.lat],
//         ];


//         const center = {
//           lat: (ne.lat() + sw.lat()) / 2,
//           lng: (ne.lng() + sw.lng()) / 2,
//         };


//         if (
//           previousBounds.current &&
//           JSON.stringify(previousBounds.current) !== JSON.stringify(newBounds)
//         ) {
//           const searchType = "boundary";
//           clearLocalStorage();
//           localStorage.setItem("searchType", searchType);
//           localStorage.setItem("boundary", JSON.stringify(newBounds));
//           localStorage.setItem("mapCenter", JSON.stringify(center));

//           dispatch(fetchProperties('', '', '', 0, JSON.stringify(newBounds)));
//           dispatch(updatePageNumber(0));
//           console.log("Dispatched Map Boundaries:", JSON.stringify(newBounds, null, 4));
//         }
  
//         setMapBounds(newBounds);
//         previousBounds.current = newBounds;
//       }
//     }
//   };

//   const clearLocalStorage = () => {
//     Object.keys(localStorage)
//       .filter(key => key.startsWith('properties_page_'))
//       .forEach(key => localStorage.removeItem(key));
//   };

//   const updateMapCenter = (newCenter) => {
//     setIsUserInteraction(false); 
//     setMapCenter(newCenter);
//     localStorage.setItem("mapCenter", JSON.stringify(newCenter));
//     setTimeout(() => {
//       setIsUserInteraction(true);
//     }, 100); 
//   };

//   if (loading || !isLoaded) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     );
//   }

//   if (loadError) {
//     return (
//       <div>
//         <p>
//           Error loading maps. Please try refreshing the page or check your
//           internet connection.
//         </p>
//       </div>
//     );
//   }

//   return (
//     <div style={{ height: "79vh", width: "99%", marginTop: "48px" }}>
//       <GoogleMap
//         center={mapCenter || coordinates}
//         zoom={5}
//         mapContainerStyle={{ height: "100%", width: "100%" }}
//         options={{
//           zoomControl: true,
//           fullscreenControl: true,
//         }}
//         onIdle={handleMapIdle}
//         onLoad={(map) => (mapRef.current = map)}
//         onClick={() => setIsUserInteraction(true)} 
//         onDragEnd={() => setIsUserInteraction(true)} 
//       >
//         {propertie.map((property, index) => {
//           if (property.address.lat === 'unavailable' || property.address.lon === 'unavailable') {
//             return null;
//           }

//           return (
//             <Marker
//               key={index}
//               position={{ lat: parseFloat(property.address.lat), lng: parseFloat(property.address.lon) }}
//               onClick={() => {
//                 setSelectedListing(selectedListing === property ? null : property);
//               }}
//               ref={(marker) => markerRefs.current[index] = marker}
//             >
//               {selectedListing === property && (
//                 <OverlayView
//                   position={{ lat: parseFloat(selectedListing.address.lat), lng: parseFloat(selectedListing.address.lon) }}
//                   mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//                 >
//                   <div>
//                     <MarkerListingproperty property={selectedListing} />
//                   </div>
//                 </OverlayView>
//               )}
//             </Marker>
//           );
//         })}

//         {coordinates && (
//           <Marker
//             position={coordinates}
//             icon={{
//               url: Pin,
//               scaledSize: new window.google.maps.Size(50, 50),
//             }}
//           />
//         )}
        
//       </GoogleMap>
//     </div>
//   );
// };

// export default CustomMap;

// import React, { useState, useEffect, useRef } from "react";
// import { GOOGLE_MAP_API_KEY } from "../../../../Config/config";
// import CircularProgress from '@mui/material/CircularProgress';
// import { GoogleMap, useLoadScript, Marker, OverlayView } from '@react-google-maps/api';
// import { connect } from 'react-redux';
// import { fetchProperties } from '../../../Action/propertyAction';
// import MarkerListingproperty from './MarkerListingproperty';
// import Pin from '../../../../assets/images/google-maps.png'

// const libraries = ['places'];


// const defaultCenter = { lat: 40.7128, lng: -74.0060 }; 

// const CustomMap = ({ properties }) => {
//   const [coordinates, setCoordinates] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [mapBounds, setMapBounds] = useState(null);
//   const mapRef = useRef(null);
//   const [mapCenter, setMapCenter] = useState(defaultCenter);
//   const [selectedListing, setSelectedListing] = useState(null);
//   const markerRefs = useRef([]);

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: `${GOOGLE_MAP_API_KEY}`,
//     libraries,
//   })

//   useEffect(() => {
//     if (properties.length) {
//       const propertiesWithLatLng = properties.filter(property => property.address.lat !== 'unavailable' && property.address.lon !== 'unavailable');
      
//       if (propertiesWithLatLng.length) {
//         const sumLat = propertiesWithLatLng.reduce((acc, property) => acc + parseFloat(property.address.lat), 0);
//         const sumLng = propertiesWithLatLng.reduce((acc, property) => acc + parseFloat(property.address.lon), 0);
//         const avgLat = sumLat / propertiesWithLatLng.length;
//         const avgLng = sumLng / propertiesWithLatLng.length;
//         setMapCenter({ lat: avgLat, lng: avgLng });
//       }
//     }
//   }, [properties]);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const newCenter = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           };
//           setCoordinates(newCenter);
//           localStorage.setItem('coordinates', JSON.stringify(newCenter));
//           setLoading(false);
//         },
//         (error) => {
//           console.error("Error fetching the user's location: ", error);
//           setLoading(false);
//         }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//       setLoading(false);
//     }
//   }, []);

//   const handleMapIdle = () => {
//     if (mapRef.current) {
//       const bounds = mapRef.current.getBounds();
//       if (bounds) {
//         const ne = bounds.getNorthEast();
//         const sw = bounds.getSouthWest();
//         const nw = { lat: ne.lat(), lng: sw.lng() };
//         const se = { lat: sw.lat(), lng: ne.lng() };

//         console.log("Map Boundaries:", { 
//           ne: { lat: ne.lat(), lng: ne.lng() },
//           nw,
//           se,
//           sw: { lat: sw.lat(), lng: sw.lng() }
//         });

//         setMapBounds({
//           ne: { lat: ne.lat(), lng: ne.lng() },
//           nw,
//           se,
//           sw: { lat: sw.lat(), lng: sw.lng() }
//         });
//       }
//     }
//   };

//   if (loading || !isLoaded) {
//     return (
//       <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//         <CircularProgress />
//       </div>
//     );
//   }

//   if (loadError) {
//     return <div>Error loading maps</div>;
//   }

  
//   if (!isLoaded) {
//     return <div>Loading maps</div>;
//   }

//   return (
//     <div style={{ height: '79vh', width: '99%', marginTop: '48px' }}>
//       <GoogleMap
//         center={mapCenter || coordinates}
//         zoom={5}
//         mapContainerStyle={{ height: '100%', width: '100%' }}
//         options={{
//           // disableDefaultUI: true,
//           zoomControl: true,
//           fullscreenControl: true,
//         }}
//         onIdle={handleMapIdle}
//         onLoad={(map) => (mapRef.current = map)}
//       >
//         {properties.map((property, index) => {
//           if (property.address.lat === 'unavailable' || property.address.lon === 'unavailable') {
//             return null;
//           }

//           return (
//             <Marker
//               key={index}
//               position={{ lat: parseFloat(property.address.lat), lng: parseFloat(property.address.lon) }}
//               onClick={() => {
//                 setSelectedListing(selectedListing === property ? null : property);
//               }}
//               ref={(marker) => markerRefs.current[index] = marker}
//             >
//               {selectedListing === property && (
//                 <OverlayView
//                   position={{ lat: parseFloat(selectedListing.address.lat), lng: parseFloat(selectedListing.address.lon) }}
//                   mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//                 >
//                   <div>
//                     <MarkerListingproperty property={selectedListing} />
//                   </div>
//                 </OverlayView>
//               )}
//             </Marker>
//           );
//         })}

//         {coordinates && (
//           <Marker position={coordinates}
//           icon={{
//             url: Pin, 
//             scaledSize: new window.google.maps.Size(50, 50), 
//           }} />
//         )}
//       </GoogleMap>
//     </div>
//   );
// };

// const mapStateToProps = (state) => ({
//   properties: state.properties.properties,
// });

// const mapDispatchToProps = {
//   fetchProperties,
// };

// export default connect(mapStateToProps, mapDispatchToProps)(CustomMap);


// import React, { useState, useEffect, useRef } from "react";
// import { GOOGLE_MAP_API_KEY } from "../../../../Config/config";
// import CircularProgress from '@mui/material/CircularProgress';
// import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
// import { useSelector, useDispatch } from 'react-redux';
// import { setCoordinates, setMapBounds } from '../../../Action/mapaction'; 
// import { fetchProperties } from "../../../Action/propertyAction";


// const SimpleMap = () => {
//   const dispatch = useDispatch();
//   const coordinates = useSelector((state) => state.map.coordinates);
//   const mapBounds = useSelector((state) => state.map.mapBounds);

//   const [loading, setLoading] = useState(true);
//   const [mapLoaded, setMapLoaded] = useState(false);
//   const mapRef = useRef(null);
//   const previousBounds = useRef(null);

//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: GOOGLE_MAP_API_KEY,
//   });

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const newCenter = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           };
//           console.log("Retrieved coordinates:", newCenter); 
//           dispatch(setCoordinates(newCenter));
//           localStorage.setItem('coordinates', JSON.stringify(newCenter));
//           setLoading(false);
//         },
//         (error) => {
//           console.error("Error fetching the user's location: ", error);
//           setLoading(false);
//         },
//         {
//           enableHighAccuracy: true, 
//           timeout: 10000, 
//           maximumAge: 0, 
//         }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//       setLoading(false);
//     }
//   }, [dispatch]);
  

//   const handleMapIdle = () => {
    

//     if (mapRef.current) {
//       const bounds = mapRef.current.getBounds();
//       if (bounds) {
//         const ne = bounds.getNorthEast();
//         const sw = bounds.getSouthWest();
  
        
//         const nw = { lat: ne.lat(), lng: sw.lng() }; 
//         const se = { lat: sw.lat(), lng: ne.lng() };
  
//         const newBounds  = [
//           [nw.lng, nw.lat],  
//           [ne.lng(), ne.lat()],  
//           [se.lng, se.lat],  
//           [sw.lng(), sw.lat()],  
//           [nw.lng, nw.lat], 
//         ];
  
//         dispatch(setMapBounds(newBounds)); 

//         if (previousBounds.current && JSON.stringify(previousBounds.current) !== JSON.stringify(newBounds)) {
//           const searchType = 'boundary';  
//           localStorage.setItem('searchType', searchType);
  
  
//           console.log("Dispatched Map Boundaries:", newBounds); 
//         }
  
//         previousBounds.current = newBounds;
//       }
//     }
//   };
  

//   if (loading || !isLoaded) {
//     return (
//       <div>
//         <CircularProgress />
//       </div>
//     );
//   }

//   const defaultCenter = { lat: 40.7128, lng: -74.0060 };

//   if (loadError) {
//     return <div>Error loading map</div>;
//   }

//   return (
//     <div style={{ height: '79vh', width: '99%', marginTop: '48px' }}>
//       <GoogleMap
//         center={coordinates || defaultCenter}
//         zoom={14}
//         mapContainerStyle={{ height: '100%', width: '100%' }}
//         options={{
//           // disableDefaultUI: true,
//           zoomControl: true,
//           fullscreenControl: true,
//         }}
//         onIdle={handleMapIdle}
//          onLoad={(map) => {
//           mapRef.current = map;
//           setMapLoaded(true); 
//         }}
//       >
//         {coordinates && (
//           <Marker position={coordinates} />
//         )}
//         {!coordinates && (
//           <Marker position={defaultCenter} />
//         )}
//       </GoogleMap>
//     </div>
//   );
// };

// export default SimpleMap;


import React, { useState, useEffect, useRef, useCallback } from "react";
import { GOOGLE_MAP_API_KEY } from "../../../../Config/config";
import CircularProgress from "@mui/material/CircularProgress";
import { GoogleMap, useLoadScript, Marker, OverlayView , StreetViewPanorama } from '@react-google-maps/api';
import Pin from "../../../../assets/images/google-maps.png";
import { useDispatch } from "react-redux";
import { fetchProperties, updatePageNumber } from "../../../Action/propertyAction";
import MarkerListingproperty from './MarkerListingproperty';
import _ from "lodash";
import Pins from '../../../../assets/images/location-2.png'

const libraries = ['places'];
const defaultCenter = { lat: 40.7128, lng: -74.0060 };

const CustomMap = ({ propertie }) => {
  const [coordinates, setCoordinates] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapBounds, setMapBounds] = useState(null);
  const mapRef = useRef(null);
  const previousBounds = useRef(null);
  const dispatch = useDispatch();
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [selectedListing, setSelectedListing] = useState(null);
  const markerRefs = useRef([]);
  const [isUserInteraction, setIsUserInteraction] = useState(true);
  const [isResizing, setIsResizing] = useState(false);
  const [isStreetViewVisible, setStreetViewVisible] = useState(false);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: `${GOOGLE_MAP_API_KEY}`,
    libraries,
  });

  useEffect(() => {
    const savedCenter = localStorage.getItem("mapCenter");
    if (savedCenter) {
      setMapCenter(JSON.parse(savedCenter));
    }
  }, []);

  useEffect(() => {
    const searchType = localStorage.getItem('searchType');
    if (searchType === 'address' && propertie.length) {
      const propertiesWithLatLng = propertie.filter(
        (property) => property.address.lat !== 'unavailable' && property.address.lon !== 'unavailable'
      );

      if (propertiesWithLatLng.length) {
        const sumLat = propertiesWithLatLng.reduce(
          (acc, property) => acc + parseFloat(property.address.lat),
          0
        );
        const sumLng = propertiesWithLatLng.reduce(
          (acc, property) => acc + parseFloat(property.address.lon),
          0
        );
        const avgLat = sumLat / propertiesWithLatLng.length;
        const avgLng = sumLng / propertiesWithLatLng.length;
        const newCenter = { lat: avgLat, lng: avgLng };
        updateMapCenter(newCenter);  
      }
    }
  }, [propertie]);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const newCenter = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCoordinates(newCenter);
          localStorage.setItem("coordinates", JSON.stringify(newCenter));
          setLoading(false);
        },
        (error) => {
          console.error("Error fetching the user's location: ", error);
          setLoading(false);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setIsResizing(true);
      setTimeout(() => setIsResizing(false), 1000); 
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clearLocalStorage = () => {
    Object.keys(localStorage)
      .filter(key => key.startsWith('properties_page_'))
      .forEach(key => localStorage.removeItem(key));
  };

  const updateMapCenter = (newCenter) => {
    setIsUserInteraction(false); 
    setMapCenter(newCenter);
    localStorage.setItem("mapCenter", JSON.stringify(newCenter));
    setTimeout(() => {
      setIsUserInteraction(true);
    }, 100); 
  };

  const debouncedFetchProperties = useCallback(
    _.debounce((bounds, center) => {
      const searchType = "boundary";
      clearLocalStorage();
      localStorage.setItem("searchType", searchType);
      localStorage.setItem("boundary", JSON.stringify(bounds));
      localStorage.setItem("mapCenter", JSON.stringify(center));

      dispatch(fetchProperties('', '', '', 0, JSON.stringify(bounds)));
      dispatch(updatePageNumber(0));
      console.log("Dispatched Map Boundaries:", JSON.stringify(bounds, null, 4));
    }, 300),
    []
  );

  const handleMapIdle = () => {
    if (isResizing) return;  
  
    if (mapRef.current && isUserInteraction) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        const nw = { lat: ne.lat(), lng: sw.lng() };
        const se = { lat: sw.lat(), lng: ne.lng() };
  
        const newBounds = [
          [nw.lng, nw.lat],
          [ne.lng(), ne.lat()],
          [se.lng, se.lat],
          [sw.lng(), sw.lat()],
          [nw.lng, nw.lat], 
        ];

        const center = {
          lat: (ne.lat() + sw.lat()) / 2,
          lng: (ne.lng() + sw.lng()) / 2,
        };

        if (
          previousBounds.current &&
          JSON.stringify(previousBounds.current) !== JSON.stringify(newBounds)
        ) {
          debouncedFetchProperties(newBounds, center);
        }
  
        setMapBounds(newBounds);
        previousBounds.current = newBounds;
      }
    }
  };

  if (loading || !isLoaded) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (loadError) {
    return (
      <div>
        <p>
          Error loading maps. Please try refreshing the page or check your
          internet connection.
        </p>
      </div>
    );
  }

  const toggleStreetView = () => {
    setStreetViewVisible(!isStreetViewVisible);

    if (!isStreetViewVisible && mapRef.current) {
    
      const map = mapRef.current;
      // const fullscreenControl = map.getDiv().querySelector('.gm-fullscreen-control');
      // if (fullscreenControl) {
      //   fullscreenControl.click(); 
      // }
    }
  };

  return (
    <div style={{ height: "79vh", width: "99%", marginTop: "48px" }}>
      <GoogleMap
        center={mapCenter || coordinates}
        zoom={5}
        mapContainerStyle={{ height: "100%", width: "100%" }}
        options={{
          zoomControl: true,
          fullscreenControl: true,
        }}
        onIdle={handleMapIdle}
        onLoad={(map) => (mapRef.current = map)}
        onClick={() => setIsUserInteraction(true)} 
        onDragEnd={() => setIsUserInteraction(true)} 
      >
        {propertie.map((property, index) => {
          if (property.address.lat === 'unavailable' || property.address.lon === 'unavailable') {
            return null;
          }

          const position = { lat: parseFloat(property.address.lat), lng: parseFloat(property.address.lon) };

          return  property.prop_status === 'FOR SALE' ? (
            <Marker
              key={index}
              // icon={{
              //   url: Pins, 
              //   scaledSize: new window.google.maps.Size(50, 30), 
              // }}
              position={position}             
               onClick={() => {
                setSelectedListing(selectedListing === property ? null : property);
              }}
              ref={(marker) => markerRefs.current[index] = marker}
  
            >
              {selectedListing === property && (
                <OverlayView
                position={position}                 
                 mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                >
                  <div>
                    <MarkerListingproperty property={selectedListing} />
                  </div>
                </OverlayView>
              )}
                       {isStreetViewVisible && (
            <StreetViewPanorama
            position={position}  
            visible={isStreetViewVisible}
            onCloseClick={() => setStreetViewVisible(false)}
          />
          
           )}
            </Marker>
          ) : (
            <Marker
            key={index}
            position={position}
            onClick={() => {
              setSelectedListing(selectedListing === property ? null : property);
            }}
            ref={(marker) => (markerRefs.current[index] = marker)}
          >
            {selectedListing === property && (
              <OverlayView
                position={position}
                mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
              >
                <div>
                  <MarkerListingproperty property={selectedListing} />
                </div>
              </OverlayView>
            )}
          </Marker>
       );
      })}

        {coordinates && (
          <Marker
            position={coordinates}
            icon={{
              url: Pin,
              scaledSize: new window.google.maps.Size(50, 50),
            }}
          />
        )}
        
      </GoogleMap>
      <button
        className="absolute bottom-6 right-16 bg-white border rounded p-2 shadow"
        onClick={toggleStreetView}
        >
          {isStreetViewVisible ? 'Exit Street View' : 'Street View'}
        </button>


    </div>
  );
};

export default CustomMap;



// import React, { useState, useEffect, useRef, useCallback } from "react";
// import { GOOGLE_MAP_API_KEY } from "../../../../Config/config";
// import CircularProgress from "@mui/material/CircularProgress";
// import { GoogleMap, useLoadScript, Marker, OverlayView, StreetViewPanorama } from '@react-google-maps/api';
// import Pin from "../../../../assets/images/google-maps.png";
// import { useDispatch } from "react-redux";
// import { fetchProperties, updatePageNumber } from "../../../Action/propertyAction";
// import MarkerListingproperty from './MarkerListingproperty';
// import _ from "lodash";
// import Pins from '../../../../assets/images/location-2.png'

// const libraries = ['places'];
// const defaultCenter = { lat: 40.7128, lng: -74.0060 };

// const CustomMap = ({ propertie }) => {
//   const [coordinates, setCoordinates] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [mapBounds, setMapBounds] = useState(null);
//   const mapRef = useRef(null);
//   const previousBounds = useRef(null);
//   const dispatch = useDispatch();
//   const [mapCenter, setMapCenter] = useState(defaultCenter);
//   const [selectedListing, setSelectedListing] = useState(null);
//   const markerRefs = useRef([]);
//   const [isUserInteraction, setIsUserInteraction] = useState(true);
//   const [isResizing, setIsResizing] = useState(false);
//   const [isStreetViewVisible, setStreetViewVisible] = useState(false);


//   const { isLoaded, loadError } = useLoadScript({
//     googleMapsApiKey: `${GOOGLE_MAP_API_KEY}`,
//     libraries,
//   });

//   useEffect(() => {
//     const savedCenter = localStorage.getItem("mapCenter");
//     if (savedCenter) {
//       setMapCenter(JSON.parse(savedCenter));
//     }
//   }, []);

//   useEffect(() => {
//     const searchType = localStorage.getItem('searchType');
//     if (searchType === 'address' && propertie.length) {
//       const propertiesWithLatLng = propertie.filter(
//         (property) => property.address.lat !== 'unavailable' && property.address.lon !== 'unavailable'
//       );

//       if (propertiesWithLatLng.length) {
//         const sumLat = propertiesWithLatLng.reduce(
//           (acc, property) => acc + parseFloat(property.address.lat),
//           0
//         );
//         const sumLng = propertiesWithLatLng.reduce(
//           (acc, property) => acc + parseFloat(property.address.lon),
//           0
//         );
//         const avgLat = sumLat / propertiesWithLatLng.length;
//         const avgLng = sumLng / propertiesWithLatLng.length;
//         const newCenter = { lat: avgLat, lng: avgLng };
//         updateMapCenter(newCenter);  
//       }
//     }
//   }, [propertie]);

//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const newCenter = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           };
//           setCoordinates(newCenter);
//           localStorage.setItem("coordinates", JSON.stringify(newCenter));
//           setLoading(false);
//         },
//         (error) => {
//           console.error("Error fetching the user's location: ", error);
//           setLoading(false);
//         },
//         {
//           enableHighAccuracy: true,
//           timeout: 10000,
//           maximumAge: 0,
//         }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//       setLoading(false);
//     }
//   }, []);

//   useEffect(() => {
//     const handleResize = () => {
//       setIsResizing(true);
//       setTimeout(() => setIsResizing(false), 1000); 
//     };
    
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const clearLocalStorage = () => {
//     Object.keys(localStorage)
//       .filter(key => key.startsWith('properties_page_'))
//       .forEach(key => localStorage.removeItem(key));
//   };

//   const updateMapCenter = (newCenter) => {
//     setIsUserInteraction(false); 
//     setMapCenter(newCenter);
//     localStorage.setItem("mapCenter", JSON.stringify(newCenter));
//     setTimeout(() => {
//       setIsUserInteraction(true);
//     }, 100); 
//   };

//   const debouncedFetchProperties = useCallback(
//     _.debounce((bounds, center) => {
//       const searchType = "boundary";
//       clearLocalStorage();
//       localStorage.setItem("searchType", searchType);
//       localStorage.setItem("boundary", JSON.stringify(bounds));
//       localStorage.setItem("mapCenter", JSON.stringify(center));

//       dispatch(fetchProperties('', '', '', 0, JSON.stringify(bounds)));
//       dispatch(updatePageNumber(0));
//       console.log("Dispatched Map Boundaries:", JSON.stringify(bounds, null, 4));
//     }, 300),
//     []
//   );

//   const handleMapIdle = () => {
//     if (isResizing) return;  
  
//     if (mapRef.current && isUserInteraction) {
//       const bounds = mapRef.current.getBounds();
//       if (bounds) {
//         const ne = bounds.getNorthEast();
//         const sw = bounds.getSouthWest();
//         const nw = { lat: ne.lat(), lng: sw.lng() };
//         const se = { lat: sw.lat(), lng: ne.lng() };
  
//         const newBounds = [
//           [nw.lng, nw.lat],
//           [ne.lng(), ne.lat()],
//           [se.lng, se.lat],
//           [sw.lng(), sw.lat()],
//           [nw.lng, nw.lat], 
//         ];

//         const center = {
//           lat: (ne.lat() + sw.lat()) / 2,
//           lng: (ne.lng() + sw.lng()) / 2,
//         };

//         if (
//           previousBounds.current &&
//           JSON.stringify(previousBounds.current) !== JSON.stringify(newBounds)
//         ) {
//           debouncedFetchProperties(newBounds, center);
//         }
  
//         setMapBounds(newBounds);
//         previousBounds.current = newBounds;
//       }
//     }
//   };

//   if (loading || !isLoaded) {
//     return (
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           height: "100vh",
//         }}
//       >
//         <CircularProgress />
//       </div>
//     );
//   }

//   if (loadError) {
//     return (
//       <div>
//         <p>
//           Error loading maps. Please try refreshing the page or check your
//           internet connection.
//         </p>
//       </div>
//     );
//   }

//   const toggleStreetView = () => {
//     setStreetViewVisible(!isStreetViewVisible);

//     if (!isStreetViewVisible && mapRef.current) {
    
//       const map = mapRef.current;
//       // const fullscreenControl = map.getDiv().querySelector('.gm-fullscreen-control');
//       // if (fullscreenControl) {
//       //   fullscreenControl.click(); 
//       // }
//     }
//   };

  

//   return (
//     <div style={{ height: "79vh", width: "99%", marginTop: "48px" }}>
//       <GoogleMap
//         center={mapCenter || coordinates}
//         zoom={5}
//         mapContainerStyle={{ height: "100%", width: "100%" }}
//         options={{
//           zoomControl: true,
//           fullscreenControl: true,
//         }}
//         onIdle={handleMapIdle}
//         onLoad={(map) => (mapRef.current = map)}
//         onClick={() => setIsUserInteraction(true)} 
//         onDragEnd={() => setIsUserInteraction(true)} 
//       >
//         {propertie.map((property, index) => {
//           if (property.address.lat === 'unavailable' || property.address.lon === 'unavailable') {
//             return null;
//           }
          
           
//           const position = { lat: parseFloat(property.address.lat), lng: parseFloat(property.address.lon) };

//           return  property.prop_status === 'FOR SALE' ? (
//             <Marker
//               key={index}
//               icon={{
//                 url: Pins, 
//                 scaledSize: new window.google.maps.Size(50, 30), 
//               }}
//               position={position}             
//                onClick={() => {
//                 setSelectedListing(selectedListing === property ? null : property);
//               }}
//               ref={(marker) => markerRefs.current[index] = marker}
  
//             >
//               {selectedListing === property && (
//                 <OverlayView
//                 position={position}                 
//                  mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//                 >
//                   <div>
//                     <MarkerListingproperty property={selectedListing} />
//                   </div>
//                 </OverlayView>
//               )}
//               {isStreetViewVisible && (
//             <StreetViewPanorama
//             position={position}  
//             visible={isStreetViewVisible}
//             onCloseClick={() => setStreetViewVisible(false)}
//           />
          
//            )}

//             </Marker>
//           ) : (

//             <Marker
//             key={index}
//             position={position}
//             onClick={() => {
//               setSelectedListing(selectedListing === property ? null : property);
//             }}
//             ref={(marker) => (markerRefs.current[index] = marker)}
//           >
//             {selectedListing === property && (
//               <OverlayView
//                 position={position}
//                 mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
//               >
//                 <div>
//                   <MarkerListingproperty property={selectedListing} />
//                 </div>
//               </OverlayView>
//             )}
           
//           </Marker>
//        );
//       })}

//         {coordinates && (
//           <Marker
//             position={coordinates}
//             icon={{
//               url: Pin,
//               scaledSize: new window.google.maps.Size(50, 50),
//             }}
//           />
//         )}
        
       

//       </GoogleMap>

//       <button
//         className="absolute bottom-6 right-16 bg-white border rounded p-2 shadow"
//         onClick={toggleStreetView}
//         >
//           {isStreetViewVisible ? 'Exit Street View' : 'Street View'}
//         </button>

//     </div>
//   );
// };

// export default CustomMap;
