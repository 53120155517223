import React from 'react';


const Middleskeleton = () => {
  

  
  return (
    <div className="   items-center animate-pulse my-16 mb-4 "> 
    <div className="h-10 bg-gray-200 rounded-lg  w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    <div className="h-10 bg-gray-200 rounded-lg my-6 w-full justify-center"></div>
    
    </div>
  );
};

export default Middleskeleton;