import React from 'react';

const AccordionHeading = ({ id, title, isOpen, toggleAccordion, icon }) => {
  return (
    <h2 id={id}>
      <div  className=" flex  text-blue-800   px-4  py-5 font-bold text-xl border-b border-blue-800 ">
      <button
        type="button"
        className='flex flex-grow justify-between items-center gap-3'
        onClick={() => toggleAccordion(id)}
        aria-expanded={isOpen}
        aria-controls={`${id}-body`}
      >
        <div className="flex items-center ">
          {icon && <span className="">{icon}</span>}
          <span className="ml-2">{title}</span>
        </div>
        <svg
          className={`w-3 h-3 rotate-${isOpen ? '0' : '180'} shrink-0`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 10 6"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M9 5 5 1 1 5"
          />
        </svg>
      </button>
      </div>
    </h2>
  );
};

export default AccordionHeading;
