import React from 'react';


const sortskeleton = () => {
 

  return (
    
      <div className="animate-pulse"> 
        <h1 className="ml-6 mt-6 h-6 bg-gray-300 rounded-full mx-4 2xl:w-2/5  xl:w-2/5 lg:w-2/5 md:w-2/5 sm:w-64 w-64 justify-center "> </h1>
        <h2  className="h-4 bg-gray-300 mt-4 ml-6 rounded-full  2xl:w-64 xl:w-64 lg:w-64 md:w-64 sm:w-20 w-20 mb-8 ">
        <p className="h-4 bg-gray-300 mt-4 2xl:ml-72 xl:ml-72 lg:ml-72 md:ml-72 sm:ml-[100px] ml-[100px] rounded-full  2xl:w-64 xl:w-64 lg:w-64 md:w-64 sm:w-20 w-20 "></p>
        </h2>
      </div>
      
  );
};

export default sortskeleton;
