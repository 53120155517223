import React from 'react';


const SubHeaderskeleton = () => {
  

  
  return (
    <div className=" xl:ml-0 lg:ml-2 flex items-center animate-pulse mb-4 "> 
      <div className="flex flex-col">
        <h1 className=" h-4 bg-gray-300 rounded-full  2xl:w-64 xl:w-64 lg:w-56 md:w-64 sm:w-52 w-48 justify-center flex "></h1>
        <h2 className="h-4 bg-gray-300 rounded-full  2xl:w-64 xl:w-64 lg:w-56 md:w-64 sm:w-52 w-48 justify-center   my-2 flex flex-col"></h2>
        
        <p className="h-4 bg-gray-300 rounded-full  2xl:w-96 xl:w-96 lg:w-72 md:w-96 sm:w-72 w-64 justify-center mt-3 "></p>
        <p className="h-4 bg-gray-300 rounded-full  2xl:w-96 xl:w-96 lg:w-72 md:w-96 sm:w-72 w-64 justify-center mt-2 "></p>

        <div className="mt-8 flex">
          <div className="mr-8 flex items-center">
         
            <div className="flex flex-col mr-0">
              <span className=" h-4 bg-gray-300 rounded-full  2xl:w-44 xl:w-44 lg:w-40 md:w-44 sm:w-28 w-24 justify-center  "></span>
              <span className="h-4 bg-gray-300 rounded-full  2xl:w-44 xl:w-44 lg:w-40 md:w-44 sm:w-28 w-24 justify-center mt-2 mr-"></span>
            </div>
          </div>

        

          <div className="mr-0 flex items-center">
         
            <div className="flex flex-col">
            <span className=" h-4 bg-gray-300 rounded-full  2xl:w-44 xl:w-44 lg:w-40 md:w-44 sm:w-28 w-24  justify-center  "></span>
              <span className="h-4 bg-gray-300 rounded-full  2xl:w-44 xl:w-44 lg:w-40 md:w-44 sm:w-28 w-24 justify-center mt-2 mr-0"></span>
            </div>
          </div>

        </div>
      </div>

     
      <div className=" xl:w-56 xl:h-36  lg:w-44 lg:h-32 bg-gray-200  xl:ml-[255px] lg:ml-[99px] hidden md:block  rounded-xl  mt-12"> </div> 
    </div>
  );
};

export default SubHeaderskeleton;