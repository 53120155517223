// import React, { useState } from "react";
// import OtpInput from "react-otp-input";

// export default function OtpVerify({ setOtp, otp }) {
//   return (
//     <OtpInput
//       containerStyle={{ flex: 1, justifyContent: "space-between" }}
//       value={otp}
//       onChange={setOtp}
//       numInputs={4}
//       renderInput={(props) => <input {...props} />}
//       inputStyle={{
//         height: 50,
//         width: 50,
//         borderColor: "black",
//         borderWidth: 1,
//       }}
//     />
//   );
// }


import React, { useState } from "react";
import OtpInput from "react-otp-input";

export default function OtpVerify({ setOtp, otp }) {
  return (
    <OtpInput
      containerStyle={{ flex: 1, justifyContent: "space-between" }}
      value={otp}
      onChange={setOtp}
      numInputs={4}
      renderInput={(props) => (
        <input
          {...props}
          type="tel"
          inputMode="numeric" 
          maxLength="1"
          style={{
            height: 50,
            width: 50,
            borderColor: "black",
            borderWidth: 1,
            textAlign: "center", 
            fontSize: "18px",
          }}
        />
      )}
    />
  );
}
