import React, { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import LgScreen from './LgScreen/index';
import MdScreen from './MdScreen/index'
import SmScreen from './SmScreen/index';

const HomeDetail = () => {


  const is2XL = useMediaQuery({ query: '(min-width: 1536px)' });
  const isXL = useMediaQuery({ query: '(min-width: 1280px)' });
  const isLG = useMediaQuery({ query: '(min-width: 1024px)' });
  const isMD = useMediaQuery({ query: '(min-width: 768px)' });
  const isSM = useMediaQuery({ query: '(min-width: 640px)' });



  return (
    <div className="flex-1 justify-center flex ">
      {is2XL ? <LgScreen  /> :
        isXL ? <LgScreen  /> :
          isLG ? <LgScreen  /> :
            isMD ? <MdScreen /> :
              isSM ? <MdScreen /> :
              <SmScreen  />}
    </div>
  );
};

export default HomeDetail;

