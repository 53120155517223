import React from 'react';
import './style.css'
import CustomMap from './map'

const SubHeader = ({data}) => {
  
  if (!data) {
    return null; 
  }
  const { address } = data;
  return (
    <div className=" mx-4  mt-4"> 
      <div className="">
        <h1 className="font-semibold text-lg flex items-center">
          <span className="h-3 w-3 bg-green-700 rounded-full mr-2"></span>{data.prop_status}
        </h1>
        <h2 className="font-bold text-4xl ml-2 my-2 flex flex-col">{data.price ? `$ ${data.price}` : 'N/A'}</h2>
        
        <p className="mt-3 ml-2">
  {data.beds !== "unavailable" && (
    <>
      {data.beds} Bed
      {data.baths !== "unavailable" || data.lot_size !== "unavailable" ? (
        <span className="inline-block ml-2 mr-2">|</span>
      ) : null}
    </>
  )}
  {data.baths !== "unavailable" && (
    <>
      {data.baths} Bath
      {data.lot_size !== "unavailable" ? (
        <span className="inline-block ml-2 mr-2">|</span>
      ) : null}
    </>
  )}
  {data.lot_size !== "unavailable" && data.lot_size}
</p>


        <p className="ml-2">{data.address.line}, {data.address.city}, {data.address.state}, {data.address.state_code}, {data.address.postal_code}</p>

        <div className="mt-8 flex">

          <div className="mr-0 flex items-center">
          <svg   className="h-8 w-8 text-blue-800 mr-2"  width="24" height="24" viewBox="0 0 24 24"   strokeWidth="2" stroke="currentColor" fill="none"  strokeLinecap="round" strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <polyline points="5 12 3 12 12 3 21 12 19 12" />  <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7" />  <rect x="10" y="12" width="4" height="4" /></svg>
            <div className="flex flex-col mr-10">
              <span className="font-bold xl:text-xl text-sm">{data.prop_type}</span>
              <span className="font-regular text-sm text-gray-500 mr-6">Property-type</span>
            </div>
          </div>


       {data.year_built !== "unavailable" && (
          <div className=" flex items-center ">
          <svg className="h-8 w-8 text-blue-800 mr-2"  width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none"  strokeLinecap="round"
            strokeLinejoin="round">  <path stroke="none" d="M0 0h24v24H0z"/>  <line x1="3" y1="21" x2="21" y2="21" />  <path d="M5 21v-14l8 -4v18" />  <path d="M19 21v-10l-6 -4" />  <line x1="9" y1="9" x2="9" y2="9.01" />  <line x1="9" y1="12" x2="9" y2="12.01" />  <line x1="9" y1="15" x2="9" y2="15.01" />  <line x1="9" y1="18" x2="9" y2="18.01" /></svg>
           
            <div className="flex flex-col">
              <span className="font-bold xl:text-xl text-sm"> {data.year_built }</span>
              <span className="font-regular text-sm text-gray-500  ">Year-build</span>
            </div>
           
          </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default SubHeader;