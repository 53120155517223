import React from 'react';


const SubHeaderskeleton = () => {
  

  
  return (
    <div className=" xl:ml-24 lg:ml-8 md:ml-6 sm:ml-6 ml-0  flex items-center animate-pulse mb-4 "> 
      <div className="flex flex-col">
        <h1 className=" h-4 bg-gray-300 rounded-full  2xl:w-64 xl:w-64 lg:w-64 md:w-64 sm:w-52 w-48 justify-center flex "></h1>
        <h2 className="h-4 bg-gray-300 rounded-full  2xl:w-64 xl:w-64 lg:w-64 md:w-64 sm:w-52 w-48 justify-center   my-2 flex flex-col"></h2>
        
        <p className="h-4 bg-gray-300 rounded-full  2xl:w-96 xl:w-96 lg:w-96 md:w-96 sm:w-72 w-64 justify-center mt-3 "></p>
        <p className="h-4 bg-gray-300 rounded-full  2xl:w-96 xl:w-96 lg:w-96 md:w-96 sm:w-72 w-64 justify-center mt-2 "></p>

        <div className="mt-8 flex">
          <div className="mr-10 flex items-center">
         
            <div className="flex flex-col mr-10">
              <span className=" h-4 bg-gray-300 rounded-full  2xl:w-44 xl:w-44 lg:w-44 md:w-44 sm:w-28 w-24 justify-center  "></span>
              <span className="h-4 bg-gray-300 rounded-full  2xl:w-44 xl:w-44 lg:w-44 md:w-44 sm:w-28 w-24 justify-center mt-2 mr-6"></span>
            </div>
          </div>

        

          <div className="mr-24 flex items-center">
         
            <div className="flex flex-col">
            <span className=" h-4 bg-gray-300 rounded-full  2xl:w-44 xl:w-44 lg:w-44 md:w-44 sm:w-28 w-24  justify-center  "></span>
              <span className="h-4 bg-gray-300 rounded-full  2xl:w-44 xl:w-44 lg:w-44 md:w-44 sm:w-28 w-24 justify-center mt-2 mr-10"></span>
            </div>
          </div>

        </div>
      </div>

     
      <div className=" w-56 h-36  bg-gray-200  ml-32 hidden md:block  rounded-xl  mt-12"> </div> 
    </div>
  );
};

export default SubHeaderskeleton;

