import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import heart from '../../../assets/images/white heart.png';
import Loader from './loader';
import Listviewskeleton from './Listviewskeleton';
import { fetchProperties, fetchFavorites, likeProperty } from '../../Action/propertyAction';

const Listview = ({ properties, loading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const {  likedProperties } = useSelector(state => state.properties);

  // useEffect(() => {
  //   dispatch(fetchFavorites());
  // }, [dispatch]);

  const handleLike = (property, e) => {
    e.stopPropagation();
    dispatch(likeProperty(property.property_id, property.category));
  };
  
  const handleClick = (propertyId) => {
    navigate(`/homedetail/${propertyId}`); 
  };

  useEffect(() => {
    if (!properties.length) {
      const searchQuery = localStorage.getItem('search');
      const areaType = localStorage.getItem('areaType');
      const searchType = localStorage.getItem('searchType');
      const pageNumber = 0;
      if (searchQuery && areaType) {
        (fetchProperties(searchQuery, areaType, searchType,  pageNumber));
      }
    }
  }, [ fetchProperties, properties]);
  


  if (loading) {
    return <Listviewskeleton />;
  }

  return (
    <div className="bg-white">
      <div className="px-4 py-14">
      {properties.length ? ( 
        <div className="mt-0 grid grid-cols-1 gap-x-6 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 md:grid-cols-2 xl:grid-cols-3  text-white">
          {properties.map((property, index) => (
            <div key={index} onClick={() => handleClick(property.property_id)} className="cursor-pointer shadow-2xl ">

              <div className="group relative rounded-md overflow-hidden shadow-2xl">
                <div className="aspect-h-1 aspect-w-1 w-full bg-ray-200 lg:aspect-none lg:h-80  md:aspect-none md:h-80 aspect-none h-80  sm:aspect-none sm:h-80 ">
                  <img
                    loading="lazy"
                    src={property.thumbnail}
                    alt={`property-${index}`}
                    className="h-full w-full object-cover object-center hover:scale-110 ease-in-out duration-500"
                  />
                  <div className="absolute top-4 right-4  flex">
                  <button
                        onClick={(e) => handleLike(property, e)}
                        className={`focus:outline-none flex items-center justify-center ${
                          likedProperties.includes(property.property_id) ? 'text-red-500 hover:scale-150 ease-in-out duration-500' : 'text-white hover:scale-150 ease-in-out duration-500 '
                        }`}
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" width="36" height="30" viewBox="0 0 24 24" fill={likedProperties.includes(property.property_id) ? 'red' : 'currentColor'}>
                          <path d="M12 4.248c-3.148-5.402-12-3.825-12 2.944 0 4.661 5.571 9.427 12 15.808 6.43-6.381 12-11.147 12-15.808 0-6.792-8.875-8.306-12-2.944z"/>
                        </svg>
                      </button>
                    </div>
                  <div className="absolute top-0 left-0 flex flex-col">
                  <div
  className={`py-1 pl-2 pr-6 bg-opacity-80 rounded-tl-md ${
    property.prop_status === 'FOR SALE' ? 'bg-green-600' : 
    property.prop_status === 'FOR RENT' ? 'bg-green-600' :
    property.prop_status === 'OFF MARKET' ? 'bg-red-600' :
    property.prop_status === 'SOLD' ? 'bg-red-600' :
    property.prop_status === 'PENDING/NEW' ? 'bg-yellow-600' :
    property.prop_status === 'READY TO BUILD' ? 'bg-yellow-600' : 'bg-yellow-600'
  }`}
>
  {property.prop_status}
</div>

                    <div className="py-1 pl-2 pr-6 bg-company-color bg-opacity-80">$ {property.price}</div>
                  </div>
                  <div className="absolute bottom-0 left-0 w-full">
                    <div className="flex gap-4 self-stretch px-4 py-0.5 text-sm sm:text-xs text-center bg-company-color bg-opacity-80">
                    <div className="flex-auto text-md">
                       {property.prop_type && `${property.prop_type}`} 
                       {property.beds > 0 && ` | ${property.beds} beds`} 
                       {property.baths > 0 && ` | ${property.baths} baths`} 
                       {property.lot_size !== "unavailable" && ` | ${property.lot_size}`}

                     </div>

                    </div>
                    <div className="flex gap-4 text-base font-medium text-blue-800 whitespace-nowrap max-md:flex-wrap w-1/1">
                      <div className="grow justify-center py-1.5 pl-1 bg-zinc-300 bg-opacity-80">
                        <div className="truncate text-xs ">
                          {property.address.line}, {property.address.city}, {property.address.state},{' '}
                          {property.address.state_code}, {property.address.postal_code}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
       ) : (
        <div className="text-center text-gray-600 mt-24">No properties found for this location.</div>
      )}
    </div>
  </div>
);
};



export default Listview;

